import Dynamic from '@/components/modals/dynamic.tsx'
import { Badge } from '@/shadcn/components/ui/badge.tsx'
import { Button } from '@/shadcn/components/ui/button.tsx'
import { DialogDescription, DialogHeader, DialogTitle } from '@/shadcn/components/ui/dialog.tsx'
import { cn } from '@/shadcn/lib/utils.ts'
import { AnimatePresence, motion } from 'framer-motion'
import { OTPInput, SlotProps } from 'input-otp'
import { Loader2, TicketCheck, Tickets } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'

export default function InputVoucherCodeModal() {
  const CODE_MAX_LENGTH = 7

  const [code, setCode] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  const otpRef = useRef<HTMLInputElement>(null)
  const submitButtonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (code.length === CODE_MAX_LENGTH) {
      otpRef.current.blur()
      setIsSubmitting(true)
      setTimeout(() => {
        setIsSubmitting(false)
        setIsChecked(true)
        submitButtonRef.current?.focus()
      }, 2000)
    }
  }, [code])

  return (
    <Dynamic.Content className="p-6 sm:p-8 focus:ring-0 focus:border-0">
      <motion.div layoutId={'input-voucher-code'} className="pt-4 sm:pt-0">
        <DialogHeader className="mb-2 flex flex-row items-center text-left pt-4 sm:pt-0">
          <Tickets className="w-8 h-8 mr-2" />
          <div>
            <DialogTitle>Coupon eingeben</DialogTitle>
            <DialogDescription>
              Gib einen 7-stelligen Couponcode ein, um fortzufahren
            </DialogDescription>
          </div>
        </DialogHeader>
      </motion.div>
      <AnimatePresence>
        <div className="flex items-center justify-center p-2">
          <div className="p-2 rounded-lg">
            <motion.div layoutId={'input-otp'}>
              <OTPInput
                ref={otpRef}
                value={code}
                onChange={(newValue) => {
                  setCode(newValue)
                }}
                inputMode={'text'}
                placeholder={'XQRSRLS'}
                maxLength={7}
                autoCorrect={'off'}
                pattern={'[123456789abcdefghjkmnpqrstuvwxyz]'}
                containerClassName="group flex items-center has-[:disabled]:opacity-30 uppercase"
                render={({ slots }) => (
                  <>
                    <div className="flex">
                      {slots.slice(0, 1).map((slot, idx) => (
                        <Slot key={idx} {...slot} />
                      ))}
                    </div>
                    <FakeDash />
                    <div className="flex">
                      {slots.slice(1, 4).map((slot, idx) => (
                        <Slot key={idx} {...slot} />
                      ))}
                    </div>
                    <FakeDash />
                    <div className="flex">
                      {slots.slice(4, 7).map((slot, idx) => (
                        <Slot key={idx} {...slot} />
                      ))}
                    </div>
                  </>
                )}
              />
            </motion.div>
            <AnimatePresence mode={'popLayout'}>
              {isSubmitting && (
                <motion.div
                  key={'submitting'}
                  exit={{
                    opacity: 0,
                    y: 10,
                    transition: {
                      duration: 0.2,
                    },
                  }}
                  initial={{
                    opacity: 0,
                    y: -10,
                  }}
                  animate={{
                    opacity: 1,
                    y: 0,
                  }}
                  className="text-muted-foreground flex text-sm items-center rounded-b-lg mt-2 "
                >
                  <Badge className="border-transparent bg-gray-500 hover:bg-gray-400 text-white bezel-xs">
                    <motion.span
                      className="flex gap-x-1 items-center"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 0.1 }}
                      exit={{
                        opacity: 0,
                      }}
                    >
                      {' '}
                      Coupon wird geprüft
                      <Loader2 className="size-4 text-gray-100 animate-spin" />
                    </motion.span>
                  </Badge>
                </motion.div>
              )}
              {isChecked && (
                <motion.div
                  key={'checked'}
                  exit={{
                    opacity: 0,
                    y: -20,
                    transition: {
                      duration: 0.1,
                    },
                  }}
                  initial={{
                    opacity: 0,
                    y: -10,
                  }}
                  animate={{
                    opacity: 1,
                    y: 0,
                    transition: {
                      delay: 0.3,
                    },
                  }}
                  className="relative overflow-hidden flex text-sm items-center mt-2 "
                >
                  <Badge variant="indigoBezel">
                    <motion.span
                      className="flex gap-x-2 items-center  font-semibold"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 0.3 }}
                    >
                      Coupon gültig
                      <Tickets className="size-4 " />
                      <motion.div
                        key="shine"
                        initial={{
                          rotate: 45,
                        }}
                        animate={{
                          rotate: 45,
                          x: [-200, 200],
                          opacity: [0.4, 0.8, 0.4],
                        }}
                        transition={{
                          repeat: Infinity,
                          duration: 1.5,
                          repeatDelay: 0.5,
                        }}
                        className="z-100 absolute h-40 w-20 bg-white/0 bg-gradient-to-r from-white/0 via-white/90 to-white/0"
                      />
                    </motion.span>
                  </Badge>
                </motion.div>
              )}
            </AnimatePresence>
            <div className="flex flex-1 justify-center border-t-2 border-dashed mt-6 pt-6 border-gray-200">
              <Button variant="indigo" ref={submitButtonRef} disabled={!isChecked}>
                <TicketCheck className="w-4 h-4 mr-2" />
                Coupon Einlösen
              </Button>
            </div>
          </div>
        </div>
      </AnimatePresence>
    </Dynamic.Content>
  )
}

function Slot(props: SlotProps) {
  return (
    <div
      className={cn(
        'relative w-8 h-12 text-[1.5rem] sm:w-10 sm:h-14 sm:text-[2rem]',
        'flex items-center justify-center',
        'transition-all duration-300',
        'border-border border-y border-r first:border-l first:rounded-l-md last:rounded-r-md',
        'group-hover:border-accent-foreground/40 group-focus-within:border-accent-foreground/20',
        'outline outline-0 outline-accent-foreground/20',
        { 'outline-4 outline-indigo-500': props.isActive },
      )}
      autoCorrect={'off'}
    >
      <motion.div
        animate={{
          scale: props.char && [1, 1.1, 1, 1.05, 1],
        }}
        transition={{
          duration: 0.3,
          delay: Math.random() * 0.1,
        }}
        className="group-has-[input[data-input-otp-placeholder-shown]]:opacity-20"
      >
        {props.char ?? props.placeholderChar}
      </motion.div>
      {props.hasFakeCaret && <FakeCaret />}
    </div>
  )
}

// You can emulate a fake textbox caret!
function FakeCaret() {
  return (
    <div className="absolute pointer-events-none inset-0 flex items-center justify-center animate-caret-blink">
      <div className="w-px h-8 bg-black" />
    </div>
  )
}

// Inspired by Stripe's MFA input.
function FakeDash() {
  return (
    <div className="flex w-10 justify-center items-center">
      <div className="w-3 h-1 rounded-full bg-border" />
    </div>
  )
}
